import React from "react"
import { graphql } from "gatsby"
import { getImage } from "gatsby-plugin-image"
import NavBar from "../components/NavBar"
import Footer from "../components/Footer"
import Metatags from "../components/metatags"
import Carousel from "../components/Carousel"

const Innovision = ({ data, location }) => {

  const url = `https://speedcambrasil.com.br${location.pathname}`

  let carouselImages = []
  data.allCarouselInnovision.edges.forEach(element => {
    carouselImages.push({ img: getImage(element["node"]) })
  })

  return (
    <>
      <Metatags
        title="Speedcam Brasil - Innovision HR"
        description="Speed Cam - Rental House"
        url={url}
        image="https://speedcambrasil.com.br/logo.png"
        keywords="speed cam, rental, Phanton, Alexa Mini, Alexa 35, Innovision HD, Innovision HR, Cinemagic Revolution, Laowa 12, Laowa 24"
        language="pt-br"
      />
      <NavBar />
      <div className="container mx-auto text-center">
        <h1 className="text-4xl m-4 md:text-6xl font-semibold text-sc-yellow">
          <i>Innovision Probe II Lentes HR</i>
        </h1>
        <div className="h-20" />
        <div className="grid grid-cols-12 gap-4">
          <div className="col-span-1" />
          <div className="col-span-10">
            <Carousel picts={carouselImages} />
          </div>
        </div>
      </div>
      <div className="h-10" />
      <div className="container mx-auto text-xl m-4">
        <div className="grid grid-cols-12 gap-4">
          <div className="col-span-2" />
          <div className="col-span-8">
            <p>Conjunto inclui as lentes: 12mm, 20mm, 28mm, 40mm e 55mm</p>
            <p>&nbsp;</p>
            <p>
              A lente tubular da InnoVision oferece ótica superior e transmissão
              de luz para vídeo de alta definição.
            </p>

            <p>
              As lentes HR Prime têm um novo desenho optico utilizando cristal
              de qualidade superior.
            </p>
          </div>
        </div>
      </div>
      <div className="h-10" />
      <div className="container mx-auto text-xl">
        <div className="grid grid-cols-12 gap-4">
          <div className="col-span-3" />
          <div className="col-span-8">
            <div>
              <h1 className="my-2">Principais características:</h1>

              <ul className="list-disc list-inside">
                <li>objetivas disponíveis: 12mm, 20mm, 28mm, 40mm e 55mm</li>
                <li>Nitidez e contraste melhorados</li>
                <li>melhoria geral da imagem de ponta a ponta</li>
                <li>todas as lentes são à prova de água</li>
                <li>todas as lentes têm filtro de proteção</li>
                <li>compatíveis com os sistemas Probe II+ e ProbeII</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div className="h-10" />
      <Footer />
    </>
  )
}

export default Innovision

export const query = graphql`
  {
    allCarouselInnovision: allFile(
      filter: { relativePath: { regex: "/innovision-hr/" } }
      sort: { fields: name }
    ) {
      edges {
        node {
          id
          childImageSharp {
            gatsbyImageData(quality: 80, layout: CONSTRAINED, width: 1536)
          }
        }
      }
    }
  }
`
